import {logger, reducerLog} from "../../../util/Util";

export const ADD_TO_QUEUE = "ADD_TO_QUEUE";
export const REMOVE_FROM_QUEUE = "REMOVE_FROM_QUEUE";
export const CLEAR_QUEUE = "CLEAR_QUEUE";
export const TOGGLE_MUTE = "TOGGLE_MUTE";
export const TOGGLE_PLAY = "TOGGLE_PLAY";
export const SET_QUEUE_INDEX = "SET_QUEUE_INDEX";
export const TOGGLE_SHOW_MEDIA = "TOGGLE_SHOW_MEDIA";
export const SHOW_MEDIA = "SHOW_MEDIA";
export const SET_VISIBILITY = "SET_VISIBILITY";
export const TOGGLE_SHOW_QUEUE = "TOGGLE_SHOW_QUEUE";
export const IS_PLAYING = "IS_PLAYING";
export const REORDER_QUEUE = "REORDER_QUEUE";
export const IS_PAUSED = "IS_PAUSED";
export const GO_TO_NEXT_QUEUE_ITEM = "GO_TO_NEXT_QUEUE_ITEM";
export const GO_TO_PREV_QUEUE_ITEM = "GO_TO_PREV_QUEUE_ITEM";
export const DISABLE_PLAY_BUTTON = "DISABLE_PLAY_BUTTON";
export const ENABLE_PLAY_BUTTON = "ENABLE_PLAY_BUTTON";
const cloneDeep = require("lodash.clonedeep");

function removeItem(queueObject, item) {
    // find the index of the media if it's already in the queue
    let removedIndex = queueObject.queue.findIndex((m) => {
        // TODO data-dependent code vv
        return m.src === item.src;
    });
    if (removedIndex !== -1) {
        // remove item if it was found
        queueObject.queue.splice(removedIndex, 1)
        // if it was before the currently playing item,
        // decrement the index by 1 so the currently playing item is still playing
        if (removedIndex < queueObject.playIndex) {
            queueObject.playIndex -= 1;
        } else if (queueObject.playIndex >= queueObject.queue.length) {
            queueObject.playIndex -= 1;
        }
    }
}

export function mediaQueueReducer(queue, action) {
    reducerLog("media-q", action)

    let queueCopy = cloneDeep(queue);
    switch (action.type) {
        case SET_VISIBILITY:
            queueCopy.isVisible = action.payload === true
            break
        case REORDER_QUEUE:
            logger("reorderqueue. new order: ", action.item, "existing: ", queueCopy.queue)
            var currentItem = queueCopy.queue[queueCopy.playIndex]
            var newPlayIndex = action.item.findIndex((itm) => itm.src === currentItem.src)

            queueCopy.queue = action.item
            queueCopy.playIndex = newPlayIndex
            break;
        case TOGGLE_SHOW_QUEUE:
            queueCopy.isVisible = !queueCopy.isVisible;
            break
        case ENABLE_PLAY_BUTTON:
            queueCopy.isPlayDisabled = false;
            break;
        case DISABLE_PLAY_BUTTON:
            queueCopy.isPlayDisabled = true;
            break;
        case ADD_TO_QUEUE:
            // remove the media if it's already in the queue
            removeItem(queueCopy, action.queueMedia);

            // put the given media at the end of the queue
            queueCopy.queue.push(action.queueMedia);

            // show the queue if this is the first item
            if (queueCopy.queue.length === 1) {
                queueCopy.showMedia = true;
            }
            break;
        case REMOVE_FROM_QUEUE:
            removeItem(queueCopy, action.queueMedia);
            break;
        case CLEAR_QUEUE:
            queueCopy.queue = [];
            queueCopy.playIndex = 0;
            break;
        case IS_PLAYING:
            queueCopy.isPlaying = true;
            queueCopy.shouldBePlaying = true;
            break;
        case IS_PAUSED:
            queueCopy.isPlaying = false;
            queueCopy.shouldBePlaying = false;
            break;
        case TOGGLE_PLAY:
            //TODO: shouldBePlaying should only change here if the previous toggle attempts have all been successful.
            //   ie: If the user keeps mashing the play button it should re-do the play attempt, NOT switch into pause mode
            //   --- I think my solution here is fine --- queueCopy.shouldBePlaying = !queueCopy.isPlaying
            queueCopy.shouldBePlaying = !queueCopy.isPlaying;
            logger("\tShould be playing? ", queueCopy.shouldBePlaying)
            break;
        case TOGGLE_MUTE:
            queueCopy.isMuted = !queueCopy.isMuted;
            break;
        case SET_QUEUE_INDEX:
            queueCopy.playIndex = action.payload
            queueCopy.shouldBePlaying = true;
            break;
        case GO_TO_NEXT_QUEUE_ITEM:
            queueCopy.playIndex += 1;
            queueCopy.shouldBePlaying = true;
            // TODO we need to find out if it's actually playing
            break;
        case GO_TO_PREV_QUEUE_ITEM:
            queueCopy.playIndex -= 1;
            queueCopy.shouldBePlaying = true;
            // TODO we need to find out if it's actually playing
            break;
        default:
            break;
    }

    if (queueCopy.playIndex >= queueCopy.queue.length) {
        queueCopy.playIndex = queueCopy.queue.length - 1;
    }
    if (queueCopy.playIndex < 0) {
        queueCopy.playIndex = 0;
    }
    return queueCopy;
}