import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
// import DragResizeContainer from 'react-drag-resize';
import Anchor from "../Anchor";
import Image from "react-bootstrap/Image";
import { Resizable } from 're-resizable';
import {logger} from "../../util/Util";
import { useImageSize } from 'react-image-size';

export default function ResizableImage(props) {
    const [dimensions, { loading, error }] = useImageSize('https://example.com/image.jpg');

    let [height, setHeight] = useState("auto")
    let [width, setWidth] = useState("75%")
    let [ratio, setRatio] = useState(null)
    let [didInit, setDidInit] = useState(false)
    let imageRef = useRef()

    let url = useMemo(() => {
        let u = props.post.url
        if (props.post.url.includes("reddit.com/media?")) {
            let urlObj = new URL(props.post.url)
            u = urlObj?.searchParams?.get("url")
        }
        return u
    }, [])

    let [dragStartX, setDragStartX] = useState(null)
    let [dragStartY, setDragStartY] = useState(null)
    let imageLoad = useCallback(() => {
        if (!didInit && imageRef.current) {
            setDidInit(true)
            setHeight(imageRef.current.offsetHeight)
            setWidth(imageRef.current.offsetWidth)
            let rat = imageRef.current.offsetWidth / imageRef.current.offsetHeight
            setRatio(rat)
            // logger("Set width/height", imageRef.current.offsetWidth, imageRef.current.offsetHeight)
            // logger("ratio", rat)
        }
    }, [])

    let dragStart = useCallback((a) => {
        setDragStartX(a.screenX)
        setDragStartY(a.screenY)
    }, [])

    let dragEnd = useCallback((evnt) => {
        setDragStartX(null)
        setDragStartY(null)
    }, [])

    // TODO drag Y works, drag X does not
    let dragCap = useCallback((evnt) => {
        if (dragStartX == null || dragStartY == null) {
            return
        }
        let mouseX = evnt.screenX
        let mouseY = evnt.screenY
        if (mouseX === 0 && mouseY === 0) {
            return
        }
        // logger("dragCap", mouseX, mouseY)
        let xDistance = mouseX - dragStartX
        let absX = Math.abs(xDistance)
        let yDistance = mouseY - dragStartY
        let absY = Math.abs(yDistance)
        let useX = absX > absY
        let isIncrease = useX ? xDistance > 0 : yDistance > 0

        let newWidth, newHeight;
        if (useX) {
            newWidth = isIncrease ? width + absX : width - absX
            newHeight = newWidth / ratio;
            // newHeight = isBigger ? height + absX*ratio : height - absX*ratio
        } else {
            // newWidth = isBigger ? width + absY*ratio : width - absY*ratio
            newHeight = isIncrease ? height + absY : height - absY
            newWidth = newHeight * ratio
        }
        // logger("dragStartX", dragStartX, "dragStartY", dragStartY)
        // logger("xdistance", xDistance, "yDistance", yDistance, "useX", useX)
        // logger("isBigger", isIncrease)
        // logger("newWidth", newWidth, "newHeight", newHeight, "newRatio", newWidth / newHeight)
        setWidth(newWidth)
        setHeight(newHeight)
        evnt.preventDefault()
    }, [dragStartX, dragStartY])

    return (
       <a style={{width: "fit-content", display: "block", WebkitUserDrag: "none"}}
          key={url + "_" + props.post.id}
          onDragStart={dragStart} onDragEnd={dragEnd} onDrag={dragCap} target={"_blank"}
          href={url}>

           <Image ref={imageRef} className={"expanded_image"}
                  // style={{WebkitUserDrag: "none"}}
                  // key={url + "_" + props.post.id}
                  // onDragStart={dragStart} onDragEnd={dragEnd} onDrag={dragCap}
                  onLoad={imageLoad}
                  height={height}
                  width={width}
                  src={url}/>

       </a>

    )
}
