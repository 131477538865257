import {createContext, useCallback, useContext} from "react";
import MediaQueue from "../MediaQueue";
import {useReducerWithSessionStorage} from "../../../util/hooks/useSessionStorage";
import {
    ADD_TO_QUEUE,
    CLEAR_QUEUE,
    DISABLE_PLAY_BUTTON,
    ENABLE_PLAY_BUTTON,
    GO_TO_NEXT_QUEUE_ITEM,
    GO_TO_PREV_QUEUE_ITEM,
    IS_PAUSED,
    IS_PLAYING,
    mediaQueueReducer,
    REMOVE_FROM_QUEUE, REORDER_QUEUE,
    SET_QUEUE_INDEX, SET_VISIBILITY,
    TOGGLE_MUTE,
    TOGGLE_PLAY,
    TOGGLE_SHOW_QUEUE
} from "./mediaQueueReducer";
import {SpotifyConnect} from "../spotify/SpotifyConnect";
import {logger, SPOTIFY_ALBUM, SPOTIFY_PLAYLIST, SPOTIFY_SONG, SUCCESS_TOAST} from "../../../util/Util";
import {getSiteFromUrl} from "../util/SiteIcons";
import {MyToastContext} from "../../../util/context/MyToastContext";
import {VerifiedAccountsContext} from "../../../util/context/VerifiedAccountsContext";

export const MediaQueueWrapperContext = createContext({});

const defaultQueue = {
    queue: [],
    isVisible: true,
    playIndex: 0,
    isMuted: false,
    isPlaying: false,
    isPlayDisabled: false,
    shouldBePlaying: false
}


/**
 * This function modifies the queue data after we retrieve it from session storage
 * @param data
 */
function sessionStorageCallback(data){
    data.shouldBePlaying = false;
    data.isPlaying = false;
}

export function MediaQueueWrapper(props) {

    let toastContext = useContext(MyToastContext);
    let userContext = useContext(VerifiedAccountsContext);


    let [queueData, queueDispatch] =
        useReducerWithSessionStorage("queue", mediaQueueReducer, defaultQueue, sessionStorageCallback)

    let isLastItem = useCallback(() => {
        return queueData.playIndex + 1 === queueData.queue.length
    }, [queueData.playIndex, queueData.queue]);

    let isFirstItem = useCallback(() => {
        return queueData.playIndex === 0;
    }, [queueData.playIndex]);
    let reorderQueue = (newOrder, data2) => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: REORDER_QUEUE, item: newOrder});
    }

    let addToQueue = (media) => {
        queueDispatch({type: ADD_TO_QUEUE, queueMedia: media})
        toastContext.addToast("Added to queue", SUCCESS_TOAST, 1000)
    }
    let setVisibility = (isVisible) => {
        queueDispatch({type: SET_VISIBILITY, payload: isVisible})
    }

    let removeFromQueue = (media) => {
        queueDispatch({type: REMOVE_FROM_QUEUE, queueMedia: media})
    }

    let clearQueue = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: CLEAR_QUEUE})
    }
    let toggleShowMediaQueue = () => {
        queueDispatch({type: TOGGLE_SHOW_QUEUE});
    }

    let toggleMute = () => {
        queueDispatch({type: TOGGLE_MUTE});
    }

    let goToItemInQueue = (index) => {
        queueDispatch({type: SET_QUEUE_INDEX, payload: index})
    }

    /**
     * This should be called when we know the media is playing
     */
    let setPlayingDisplay = useCallback(() => {
        if (!queueData.isPlaying) {
            // noinspection JSCheckFunctionSignatures
            queueDispatch({type: IS_PLAYING});
        }
    }, [queueData.isPlaying, queueDispatch])

    /**
     * This should be called when we know the media has been paused
     */
    let setPausedDisplay = useCallback(() => {
        if (queueData.isPlaying) {
            // noinspection JSCheckFunctionSignatures
            queueDispatch({type: IS_PAUSED});
        }
    }, [queueData.isPlaying, queueDispatch])

    /**
     * This should be called when the user clicks the play/pause button and we need to tell the media to play/pause
     */
    let togglePlay = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: TOGGLE_PLAY});
    }

    let nextQueueItem = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: GO_TO_NEXT_QUEUE_ITEM});
    }

    let prevQueueItem = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: GO_TO_PREV_QUEUE_ITEM});
    }


    let disablePlayButtonToggle = (shouldDisable) => {
        if (queueData.isPlayDisabled && !shouldDisable) {
            // enable
            queueDispatch({type: ENABLE_PLAY_BUTTON});
        } else if (!queueData.isPlayDisabled && shouldDisable) {
            // disable
            queueDispatch({type: DISABLE_PLAY_BUTTON});
        }
    }

    const shouldShowMediaQueue = useCallback(() => {
        let dataExists = queueData?.queue?.length > 0;
        let disallowedPaths = ["/welcome", "/forgotPassword"]
        let isBadPath = disallowedPaths.find((path) => window.location.pathname.includes(path))
        return !isBadPath && dataExists
    }, [queueData])

    const getCurrentItem = useCallback(() => {
        return queueData.queue && queueData.queue.length > 0 ?
            queueData.queue[queueData.playIndex] :
            null
    }, [queueData.playIndex, queueData.queue])

    const getCurrentItemEmbedUrl = useCallback(() => {
        let queueItem = getCurrentItem()
        if (!queueItem) {
            return null;
        }
        if (queueItem.post?.custom_embed) {
            return queueItem.post.custom_embed
        }

        let mediaUrl = queueItem["src"]
        // For spotify: find the `src` param. For all others use `url`
        let firstParam = "url"
        let secondParam = "src"
        if (mediaUrl.includes("open.spotify.com") || mediaUrl.includes("audiomack.com")) {
            firstParam = "src";
            secondParam = "url"
        }

        let url;
        if (queueItem.className === "embedly-embed") {
            url = (new URL(mediaUrl)).searchParams.get(firstParam) || (new URL(mediaUrl)).searchParams.get(secondParam)
        } else {
            url = mediaUrl;
        }

        if (url.includes("//music.apple.com")) {
            url = url.replace("//music.apple.com", "//embed.music.apple.com")
        } else if (url.includes("twitch.tv/")) {
            if (url.includes("/clip/")) {
                    // src="https://clips.twitch.tv/embed?clip=AmazonianSpoopyTireSuperVinlin-8h-mKxC0HSwucMJd&parent=www.example.com"
                let urlObj = new URL(url)
                let urlSplit = urlObj.pathname.split("/")
                // return `https://player.twitch.tv/?video=${urlSplit[urlSplit.length-1]}&parent=roundupforreddit.com`
                return `https://clips.twitch.tv/embed?clip=${urlSplit[urlSplit.length-1]}&parent=roundupforreddit.com`
            }
        } else if (url.includes("v.redd.it")) {
            // url = queueItem?.post?.media_embed?.reddit_video?.hls_url || url
            return queueItem?.post?.media_embed?.reddit_video?.dash_url || url
        } else if (url.includes("deezer.com")) {
            let match = mediaUrl.match("/.*deezer.com/(.*)/(.*)")
            if (match && match[1] && match[2]) {
                return `https://widget.deezer.com/widget/auto/${match[1]}/${match[2]}`
            }
            // <iframe title="deezer-widget" src="https://widget.deezer.com/widget/auto/track/70018662" width="100%"
            //         height="300" frameBorder="0" allowTransparency="true"
            //         allow="encrypted-media; clipboard-write"></iframe>
            // url = queueItem?.post?.media_embed?.reddit_video?.hls_url || url
        } else if (url.includes("gfycat.com")) {
            let reg = new RegExp("gfycat.com/ifr/(.*)");
            if (!url.match(reg)) {
                reg = new RegExp("gfycat.com/([^/]*)");
                let match = url.match(reg)
                if (match && match[1]) {
                    return `https://gfycat.com/ifr/${match[1]}`
                } else {
                    logger("bad gfycat url? ", url);
                }
            }
        } else if (url.includes(".bandcamp.com")) {
            try {
                let itemId = new URL(url).searchParams.get("search_item_id")
                let itemType = new URL(url).searchParams.get("search_item_type")
                let embedType = null, size = null;
                if (itemType === "a") {
                    embedType = "album"
                    size = "large"
                } else if (itemType === "t") {
                    embedType = "track"
                    size = "small"
                }

                size = "large"
                return `https://bandcamp.com/EmbeddedPlayer/${embedType}=${itemId}/size=${size}/artwork=small/tracklist=${itemType === "a"}/bgcol=ffffff/linkcol=0687f5/transparent=true/`
            } catch (ignore) {
                // <iframe style="border: 0; width: 100%; height: 42px;"
                //         src="https://bandcamp.com/EmbeddedPlayer/album=3227262162/size=small/bgcol=ffffff/linkcol=0687f5/track=2451454808/transparent=true/"
                //         seamless><a href="https://tommyrichman.bandcamp.com/album/million-dollar-baby">MILLION DOLLAR
                //     BABY by Tommy Richman</a></iframe>
                // <iframe style="border: 0; width: 350px; height: 470px;"
                //         src="https://bandcamp.com/EmbeddedPlayer/album=3227262162/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=2451454808/transparent=true/"
                //         seamless><a href="https://tommyrichman.bandcamp.com/album/million-dollar-baby">MILLION DOLLAR
                //     BABY by Tommy Richman</a></iframe>
                logger("Error in bandcamp embed", ignore)
            }
        }
        return url;
    }, [getCurrentItem])

    const getCurrentItemSite = useCallback(() => {
        return getSiteFromUrl(getCurrentItemEmbedUrl())
    }, [getCurrentItemEmbedUrl])

    let methods = {
        setVisibility: setVisibility,
        addToQueue: addToQueue,
        getCurrentItem: getCurrentItem,
        getCurrentItemEmbedUrl: getCurrentItemEmbedUrl,
        getCurrentItemSite: getCurrentItemSite,
        setPlayingDisplay: setPlayingDisplay,
        setPausedDisplay: setPausedDisplay,
        disablePlayButtonToggle: disablePlayButtonToggle,
        togglePlay: togglePlay,
        toggleShowMediaQueue: toggleShowMediaQueue,
        removeFromQueue: removeFromQueue,
        toggleMute: toggleMute,
        reorderQueue: reorderQueue,
        nextQueueItem: nextQueueItem,
        prevQueueItem: prevQueueItem,
        goToItemInQueue: goToItemInQueue,
        clearQueue: clearQueue,
        isFirstItem: isFirstItem,
        isLastItem: isLastItem,
    }
    
    return <MediaQueueWrapperContext.Provider value={{
        // this is available to any child of the MediaQueue w/ MediaQueueContext
        ...methods,
        data: queueData,
    }}>
        <SpotifyConnect>
            {/*<DebugInfo/>*/}

            {shouldShowMediaQueue() &&
                <MediaQueue/>
            }
            {props.children}
        </SpotifyConnect>
    </MediaQueueWrapperContext.Provider>
}