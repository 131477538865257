import React, {useCallback, useContext, useMemo, useState} from 'react'
import {Button} from "react-bootstrap";
import {BsFileText, BsFillImageFill, BsLink} from "react-icons/bs";
import {FaPlay} from "react-icons/fa";
import parse from "html-react-parser";
import {cloneDeep, queueIcon} from "../../util/Util";
import Image from "react-bootstrap/Image";
import Anchor from "../Anchor";
import RedditMarkdown from "./RedditMarkdown";
import FlairAndTitle from "./FlairAndTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useDetectMobileScreen, {SM} from "../../util/hooks/useDetectMobileScreen"
import RedditLink from "./RedditLink";
import {getSiteFromUrl, getSiteIconForLink,} from "../media_queue/util/SiteIcons";
import MusicMirrors from "../media_queue/util/MusicMirrors";
import {MediaQueueWrapperContext} from "../media_queue/state/MediaQueueWrapper";
import {isApprovedSite, isEmbeddable} from "../../util/context/SubredditConfigContext";
import ScriptTag from "react-script-tag";
import * as PropTypes from "prop-types";
import ResizableImage from "./ResizableImage";
import {RoundupMatcherContext} from "./RoundupMatcher";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";

/**
 * Returns a valid url for a reddit permalink.
 * Sometimes the permalink of a post is a absolute url, sometimes it is a relative url
 * @param permalink
 */
export function getRedditPermalink(permalink) {
    return permalink.startsWith("/r/") ? `https://reddit.com${permalink}` : permalink
}

export function isSelfPost(post) {
    return (post?.url && post.url.includes(post.permalink))
}

export function isCanonLink(mirrorData, post) {
    let site1 = getSiteFromUrl(post.url)
    let site2 = getSiteFromUrl(mirrorData.otherLink)
    return site1 === site2 && site1 != null && site2 != null;
}

/**
 * Display a reddit post or comment
 * @param props
 * @constructor
 */
export default function RedditPost(props) {
    let queueContext = useContext(MediaQueueWrapperContext);

    let {expandAll, collapseAll, setExpandAll, setCollapseAll} = useContext(RoundupMatcherContext);

    let {screenWidth, breakpoint} = useDetectMobileScreen();
    let post = props.post

    // TODO This is not working. Need to just expose the setShouldExpand function to the parent
    // let [shouldExpand, setShouldExpand] = useState(isSelfPost(post));
    let [buttonClicked, setButtonClicked] = useStateWithSessionStorage(post.id + "_expand",
        {time: null, prevState: isSelfPost(post)});

    let isExpanded = useMemo(() => {
        let thisButtonTime = buttonClicked.time
        let prevState = buttonClicked.prevState
        let mostRecent = Math.max(thisButtonTime, expandAll, collapseAll)
        if (mostRecent === collapseAll && collapseAll) {
            return false
        } else if (mostRecent === expandAll && expandAll) {
            return true
        } else if (mostRecent === thisButtonTime && thisButtonTime) {
            return !prevState;
        }
        return prevState
    }, [post, expandAll, collapseAll, buttonClicked])


    let isRedditGallery = useMemo(() => {
        return post?.url?.includes("reddit.com/gallery")
    }, [post])
    /**
     * Tells us if this reddit post links to an embeddable image
     * @type {boolean}
     */
    let isImage = useMemo(() => {
        let validUrls = ["i.redd.it", "imgur.com", "reddit.com/gallery"]
        try {
            let findValid = validUrls.find((url) => post.url.includes(url))
            return findValid != null;
        }catch (e) {
            return false
        }
    }, [post.url])

    /**
     * Tells us if this reddit post links to an embeddable video
     * @type {function(): boolean}
     */
    let isVideo = useCallback(() => {
        // todo fix this. Test with /r/hockey or nfl
        // let validUrls = ["v.redd.it"]
        // let validUrls = []
        // let findValid = validUrls.find((url) => post.url.includes(url))
        // return findValid != null;
        return false;
    }, [])

    let thumbnail = useMemo(() => {
            let displayWidth;
            let divisor;
            let displayHeight;

            let getButton = (icon, cls) => {
                return <div className={`reddit_post_thumbnail customThumbnail ${cls ? cls : ''}`}>
                    {icon}
                </div>
            };

            let thumbnailObj = null;

            if (post.media_embed && post.media_embed.oembed && post.media_embed.oembed.thumbnail_url) {
                thumbnailObj = {
                    url: post.media_embed.oembed.thumbnail_url,
                    width: post.media_embed.oembed.thumbnail_width,
                    height: post.media_embed.oembed.thumbnail_height
                }
            } else if (post.media_embed && post.media_embed.t_url) {
                thumbnailObj = {
                    url: post.media_embed.t_url,
                    width: post.media_embed.t_width,
                    height: post.media_embed.t_height
                }
            }

            if (thumbnailObj) {
                displayWidth = screenWidth <= 420 ? 75 :
                        breakpoint.val <= SM.val ? 100 :
                            150;

                divisor = thumbnailObj.width / displayWidth;
                displayHeight = thumbnailObj.height / divisor;
                return <Image className={'reddit_thumbnail'}
                              width={displayWidth}
                              height={displayHeight}
                              style={{
                                  float: "left",
                                  backgroundImage: `url(${thumbnailObj.url})`,
                                  backgroundSize: `${displayWidth}px ${displayHeight}px`,
                              }}/>
            }
            if (isSelfPost(post)) {
                return getButton(<BsFileText/>)
            }
            if (isImage) {
                return getButton(<BsFillImageFill/>)
            }
            if (isVideo()) {
                return getButton(<FaPlay/>, "playIcon")
            }
            return getButton(<BsLink/>)

        }, [post, isImage, isVideo, breakpoint, screenWidth]
    )

    /**
     * Add an embedded piece of media to the queue
     * @type {(function(): void)|*}
     */
    let addToQueueButtonClicked = useCallback((mirrorLink) => {
        if (mirrorLink || embeddableNoMirrors) {
            let data = {post: post}
            if (mirrorLink) {
                data["src"] = mirrorLink.otherLink
                queueContext.addToQueue(data)

            } else if (post.custom_embed) {
                data["src"] = post.custom_embed
                queueContext.addToQueue(data)

            } else if (isApprovedSite(post)) {
                Object.assign(data, {src: post.url, title: post.title})
                queueContext.addToQueue(data)

            } else if (post && post.media_embed?.oembed) {
                let p = parse(post.media_embed.oembed.html)
                Object.assign(data, p.props)
                queueContext.addToQueue(data)

            } else if (post && post.media_embed?.reddit_video) {
                let vid = post.media_embed.reddit_video;
                let props = {
                    src: vid.fallback_url,
                    width: vid.width,
                    height: vid.height,
                    title: post.title,
                    allow: "autoplay; fullscreen"
                }
                Object.assign(data, props)
                queueContext.addToQueue(data)
            }
        } else {
            setButtonClicked({time: Date.now(), prevState: isExpanded})
        }
    }, [post, queueContext, buttonClicked, isExpanded])

    /**
     * Return the html to be displayed when an embedded piece of media is expanded
     * @type {unknown}
     */
    let expandedMedia = useMemo(() => {
        if (isExpanded) {
            let objs = []
            if (post.selftext) {
                objs.push(<RedditMarkdown key={'markdown'} atq={addToQueueButtonClicked} body={post.selftext} post={post}/>)
            }
            if (isImage) {
                if (isRedditGallery) {
                    let d =
                        <div key={post.id + "_gallery"}>
                            <blockquote className="reddit-embed-bq" style={{height: "500px"}} data-embed-height="740">
                                <a href={"https://reddit.com" + post.permalink}></a>
                            </blockquote>
                            <ScriptTag className={"reddit-embed-script"}
                                       type={"text/javascript"}
                                       src="https://embed.reddit.com/widgets.js" charSet="UTF-8"></ScriptTag>
                        </div>
                    objs.push(d)
                } else {
                    objs.push(<ResizableImage key={post.id} post={post}/>)
                }
            } else if (isVideo()) {

            }
            return objs
            // return null
        }
        return null;
    }, [isExpanded, post, isImage, isVideo, addToQueueButtonClicked])

    let embeddableNoMirrors = useMemo(() => {
        return isEmbeddable(post) && !post.mirrors?.length
    }, [post, post.mirrors])

    let expandoIcon = useMemo(() => {
        if (embeddableNoMirrors) {
            return getSiteIconForLink(post.url)
        } else if (isImage) {
            return <BsFillImageFill/>
        } else if (isVideo()) {
            return <FaPlay/>
        } else if (post.selftext) {
            return <BsFileText/>
        }
        return null
    }, [post, isVideo, isImage, embeddableNoMirrors])


    /**
     * For self-posts and image posts
     */
    let showExpandoButton = useMemo(() => {
        return post.selftext || isImage || embeddableNoMirrors
    }, [post, isImage, embeddableNoMirrors]);


    // noinspection JSUnresolvedVariable
    let permalink = useMemo(() => {
        return getRedditPermalink(post.permalink);
    }, [post.permalink])


    return (
        <Container className={"reddit_post"}>
            <Row>
                <Col xs={3} sm={2} md={3} className={"thumbnailColumn"}>
                    {thumbnail}
                </Col>
                <Col xs={9} sm={10} md={9}>
                    <div className={`reddit_post_data`}>
                        {/* Score */}
                        <RedditLink permalink={permalink} showArrow={true} post={post}/>

                        {/*Reddit post title*/}
                        <FlairAndTitle post={post}/>

                        <Row>
                            {showExpandoButton &&
                                // Enqueue / expando button
                                <Col xs={2} sm={1}>
                                    <Button aria-label={"Enqueue button"}
                                            variant={"primary"}
                                            className={"expand_or_enqueue_button"}
                                            onClick={() => addToQueueButtonClicked()}>
                                        {expandoIcon}
                                    </Button>
                                </Col>
                            }

                            <Col>
                                {/* Date */}
                                <small>
                                    <>{new Date(post.created_utc*1000).toDateString()}</>
                                    {/*by&nbsp;*/}
                                    {/*<Anchor href={`https://www.reddit.com/u/${post.author}`}>/u/{post.author}</Anchor>*/}
                                </small>
                                <br/>
                                {/*Awards*/}
                                {post.num_awards > 0 &&

                                    <small>
                                        <span><b>{post.num_awards}</b> {post.num_awards > 1 ? 'awards' : 'award'}</span>
                                    </small>
                                }
                            </Col>
                        </Row>
                        {post.mirrors && post.mirrors.length > 0 &&
                            <MusicMirrors post={post}
                                          icon={
                                              <div style={{paddingTop: "1rem"}}>
                                                  {queueIcon}
                                              </div>}
                                          usePopup={false}/>
                        }
                    </div>
                </Col>
            </Row>
            <span className={"expanded-media"}>
                {expandedMedia}
            </span>
        </Container>
    )
}
