import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import {FaCompressArrowsAlt, FaExpandArrowsAlt} from "react-icons/fa";
import CollapsableCard from "../CollapsableCard";
import {List} from "semantic-ui-react";
import RedditPost from "./RedditPost";
import RedditComment from "./RedditComment";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import {RiCollapseDiagonal2Line, RiExpandDiagonalFill} from "react-icons/ri";


export const RoundupMatcherContext = createContext({collapseAll: false, expandAll: false});

export default function RoundupMatcher(props) {
    let thisRoundup = props.thisRoundup
    let matcher = props.matcher

    let [expandAll, setExpandAll] =  useStateWithSessionStorage(
        matcher.id + "_" + thisRoundup.id + "_expand",
        null)
    let [collapseAll, setCollapseAll] =  useStateWithSessionStorage(
        matcher.id + "_" + thisRoundup.id + "_collapse",
        null)

    let header =(
        <>
            <h3 className={"matcher_title"}>
                {matcher.displayName}
            </h3>
            <Button variant={"secondary"} onClick={() => {
                setExpandAll(Date.now())
            }}>
                {/*Expand All <FaExpandArrowsAlt/>*/}
                Expand All <RiExpandDiagonalFill/>

            </Button>
            <Button variant={"secondary"} onClick={() => {
                setCollapseAll(Date.now())
            }}>
                {/*Collapse All <FaCompressArrowsAlt />*/}
                Collapse All <RiCollapseDiagonal2Line />
            </Button>
        </>
    )
    return (
        <RoundupMatcherContext.Provider value={{expandAll: expandAll, setExpandAll: setExpandAll,
            collapseAll: collapseAll, setCollapseAll: setCollapseAll}}>
            <div key={matcher.id} className={"nested_card"}>
                <CollapsableCard
                    cardId={"roundup_matcher_" + matcher.id} className={"roundup_matcher"}
                    body={
                        <List>
                            {thisRoundup?.posts[matcher.id] && thisRoundup?.posts[matcher.id].map((post) => {
                                let element;
                                if (!post.is_comment && post.title) {
                                    element = <RedditPost post={post}/>
                                } else {
                                    element = <RedditComment comment={post}/>
                                }
                                return (
                                    <List.Item key={post.id}>
                                        {element}
                                    </List.Item>
                                )
                            })}
                        </List>
                    }
                    header={header}>

                </CollapsableCard>
            </div>
        </RoundupMatcherContext.Provider>
    )
}
