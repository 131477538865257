import React, {useCallback, useContext, useMemo} from "react";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import Button from "react-bootstrap/Button";
import { MdDragHandle } from "react-icons/md";
import {FaMinus} from "react-icons/fa";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getSiteIcon} from "./util/SiteIcons";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import {List} from "semantic-ui-react";
import RedditLink from "../roundup/RedditLink";

export default function QueueItem(props) {
    let media = props.media;
    let index = props.index;
    let {isMobile} = useDetectMobileScreen();

    let queueContext = useContext(MediaQueueWrapperContext)
    // let currentMedia = queueContext.getCurrentItem()
    // let queueData = queueContext.data;
    // // let redditPost = currentMedia?.post;
    // let redditPost = useMemo(() => {
    //     queueData.queue.find()
    // }, [])
    let isCurrentItem = useMemo(() => {
        return index === queueContext.data.playIndex;
    }, [index, queueContext.data.playIndex])


    const dequeueButton = (
        <div className={"dequeue_button btn btn-danger"}
             onClick={(event) => {
                 event.stopPropagation()
                 queueContext.removeFromQueue(media)
             }}>
            <FaMinus/>
        </div>)

    const redditLink = <RedditLink hideIcon={true} id={"queue_reddit_link_" + media.post.id}
                                   includeTitle={false} post={media.post}
    />

    const titleDiv = <>
        {media?.post?.title}
    </>

    const onclick = useCallback((evnt) => {
        if (!isCurrentItem && !evnt.target.className.includes("reddit_text_span") && !evnt.target.className.includes("reddit_post_link")) {
            queueContext.goToItemInQueue(index)
        }
    }, [index, isCurrentItem])
    return (
        <Button key={index} onClick={onclick}
                className={"queue_item_button " + (isCurrentItem ? "current_queue_item" : "")}>
            <List.Item className={"queue_item"}>
                {isMobile ? (
                    // On mobile the dequeue button and the site icon should be stacked vertically
                    <Row>
                        <Col xs={1}>
                            <Row style={{flexDirection: "column", gap: ".3rem"}}>
                                {getSiteIcon(media)}
                                {dequeueButton}
                            </Row>
                        </Col>
                        <Col xs={7} className={"alignTextStart"}>
                            {titleDiv}
                        </Col>
                        <Col xs={2} className={"alignTextStart"}>
                            {redditLink}
                        </Col>
                    </Row>
                ) : (
                    //on Desktop the site icon should come after the title.
                    <Row>
                        <Col xs={2}>
                            {getSiteIcon(media)}
                        </Col>

                        <Col xs={6} className={"alignTextStart"}>
                            {titleDiv}
                        </Col>
                        <Col xs={2} className={"alignTextStart"}>
                            {redditLink}
                        </Col>
                        <Col xs={2}>
                            {dequeueButton}
                        </Col>
                    </Row>
                )}

            </List.Item>
        </Button>
    )

}