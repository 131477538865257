import React, {useMemo} from "react"
import Anchor from "../Anchor";
import Image from "react-bootstrap/Image";
import Reddit_Mark_OnWhite from "../../resources/Reddit_Mark_OnWhite.png";

import {IconContext} from "react-icons";
import {TbArrowBigUp} from "react-icons/tb";
import {BiCommentDetail} from "react-icons/bi";

export default function RedditLink(props) {
    let post = props.post;
    let permalink = useMemo(() => {
        let p = props?.permalink || post?.permalink;
        if (p && !p.includes("reddit.com")) {
            p = "https://reddit.com" + p
        }
        return p
    }, [post?.permalink, props?.permalink])
    const formatNumber = (num) => {
        return Number(num).toLocaleString("en")
    };
    let oneSpace = <>&nbsp;</>
    let twoSpaces = <>&nbsp;&nbsp;</>
    let seperator = <>{twoSpaces}<b>•</b>{twoSpaces}</>
    return ( post && (

        // Add the class, reddit_comment_icons, to all icon children
        <IconContext.Provider value={{className: 'reddit_comment_icons'}}>
            <div className={"reddit_link_and_info"} id={props.id ? props.id : ''}>
                <Anchor className={"reddit_post_link"} href={permalink}>

                    {/*Reddit icon*/}
                    {!props.hideIcon &&
                        <Image height={20}
                               width={20}
                               style={{bottom: ".2rem", position: "relative"}}
                               src={Reddit_Mark_OnWhite}/>
                    }

                    {props.includeTitle &&
                        <>
                            {oneSpace}
                            <span>{post.title}</span>
                        </>
                    }

                    {twoSpaces}
                    <span className={"reddit_text_span"}>

                        {/* Score */}
                        <span style={{fontSize: "1.1rem", fontWeight: "850"}}>
                            {formatNumber(post.score)}
                        </span>
                        {/*Arrow*/}
                        <TbArrowBigUp/>

                        {seperator}

                        {/*Num comments*/}
                        {formatNumber(post.num_comments)}
                        {oneSpace}
                        {/*Comment icon*/}
                        <BiCommentDetail/>
                    </span>
                    {/*&bull;*/}
                    {/*&nbsp;*/}
                </Anchor>
            </div>
        </IconContext.Provider>
    ))

}