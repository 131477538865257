import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import ReactPlayer from "react-player/lazy";
import useWindowDimensions from "./UseWindowDimensions";

import {R4RSpotifyPlayer} from "../spotify/R4RSpotifyPlayer";
import {logger} from "../../../util/Util";
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";

export function CustomIframe(props) {
    let queueContext = useContext(MediaQueueWrapperContext);
    let media = queueContext.getCurrentItem()
    let iframeRef = useRef();
    let reactPlayerRef = useRef();
    let isPlayingRef = useRef(false);
    let [muted, setMuted] = useState(false);
    let [shouldPlay, setShouldPlay] = useState(false);

    const {width, height, oWidth, oHeight} = useWindowDimensions();

    let isPlaylist = useMemo(() => {
        if (!media || !media.mediaType) {
            return false;
        }
        let validMediaTypes = ["playlist", "album", "playlist_or_ep", "music.album", "music.playlist"];
        // logger(`is playlist or album? media type: ${media.mediaType}, answer: ${isTrue}`)
        return validMediaTypes.includes(media.mediaType);
    }, [media]);

    useEffect(() => {
        setMuted(queueContext.data.isMuted)
    }, [queueContext.data.isMuted])

    useEffect(() => {
        setShouldPlay(queueContext.data.shouldBePlaying)
    }, [queueContext.data.shouldBePlaying])


    let playerWidth = useMemo(() => {
        // 275 < x < 1036
        // the min number is arbitrary
        let w = Math.min(width - 20, 1036)
        w = Math.max(w, 275)
        return w;
        // return width
    }, [width])

    let embedSrc = queueContext.getCurrentItemEmbedUrl()
    let playerHeight = useMemo(() => {
        if (embedSrc.includes("soundcloud.com")) {
            return 350
        }
        return 500
    }, [embedSrc])

    let siteSpecificConfig = {
        soundcloud: {
            options: {
                visual: false,
                auto_play: true,
                sharing: true,
                buying: true,
                download: true,
                show_artwork: true,
                show_user: true
            }
        },
        youtube: {
            playerVars: {
                autoplay: 1,
                controls: 1
            }
        },
        vimeo: {
            playerOptions: {
                autoplay: true,
                color: "#ADFF2F"
            }
        },
        mixcloud: {
            options: {
                autoplay: true
            }
        }
    }

    /**
     * Determines if we can use the react-player library for embedding this link
     * All supported media is listed on github. Stay up to date:
     * https://github.com/CookPete/react-player#supported-media
     */
    function canUseReactPlayer() {
        // let badUrls = []
        let badUrls = ["youtube.com/clip"]
        for (const index in badUrls) {
            let url = badUrls[index]
            if (media?.src?.includes(url)) {
                return false
            }
        }

        let validHostnames = ["youtube.com", "youtu.be", "soundcloud.com", "vimeo.com", "dailymotion.com",
            "facebook.com", "streamable.com", "mixcloud.com", "v.redd.it", "pandora.com"]; //"soundcloud.app.goo.gl", "twitch.tv"
        for (const index in validHostnames) {
            let hostname = validHostnames[index]
            if (media["src"]?.includes(hostname)) {
                return true;
            }
        }
        return false;
    }

    function onPlay() {
        // this is called when the 'play' event is triggered
        queueContext.setPlayingDisplay()
        setShouldPlay(true)
        isPlayingRef.current = true;
    }

    function onPause() {
        queueContext.setPausedDisplay()
        setShouldPlay(false)
        isPlayingRef.current = false;
    }

    function onEnded() {
        logger("media ended");
        isPlayingRef.current = false;
        if (!isPlaylist) {
            logger("Not a playlist, calling on end")
            queueContext.nextQueueItem()
        } else {
            // wait a second, check if another song gets played
            logger("This is a playlist, waiting a second")
            setTimeout(() => {
                if (!isPlayingRef.current) {
                    logger("playlist has ended I think")
                    props.onEnd();
                } else {
                    logger("playlist is still going")
                    // wait another second and check again
                    setTimeout(() => {
                        if (!isPlayingRef.current) {
                            logger("playlist has ended I think")
                            props.onEnd();
                        }
                        logger("playlist is still STILL going")
                    }, 500);
                }
            }, 500);
        }
    }

    function onError(e) {
        logger("onError", e);
    }

    function onReady(e) {
        logger("react player is ready", e.props.url, "shouldBePlaying?", shouldPlay);
    }

    /**
     * Disable the play button. This function is passed down to children
     */
    let callbackDisablePlay = useCallback((shouldDisable) => {
        queueContext.disablePlayButtonToggle(shouldDisable)
    }, [queueContext])


    if (canUseReactPlayer()) {
        return (
            // <>
            // <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay"
            //         src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1878274520&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <ReactPlayer url={embedSrc}
                     className={"react-player"}
                     controls={true}
                     onPlay={onPlay} onPause={onPause} onEnded={onEnded} onError={onError} onReady={onReady}
                     playing={shouldPlay}
                     muted={muted}
                     volume={1}
                     config={siteSpecificConfig}
                     width={playerWidth}
                     height={playerHeight}
                     ref={reactPlayerRef}
                     playsinline={true}
                     pip={true}
                     stopOnUnmount={false}
            />
            // </>
        )
    } else if (embedSrc.includes("spotify.com")) {
        return (
            <R4RSpotifyPlayer media={media}
                              onPlay={onPlay} onPause={onPause} onEnded={onEnded} onError={onError}
                              onReady={onReady}
                              playing={shouldPlay}
                              muted={muted}
                              disablePlayCallback={callbackDisablePlay}
                              volume={1}/>
        )
    } else {
        logger("USING regular old IFRAME for url: " + embedSrc)
        let className = ""
        let w = "200px"
        let h = "100px"
        if (embedSrc.includes("bandcamp.com")) {
            if (embedSrc.includes("/track=")) {
                w = "100%"
                h = "42px"
                className = "iframe_bandcamp_track"
            } else if (embedSrc.includes("/album=")) {
                w = "350px"
                h = "470px"
                className = "iframe_bandcamp_album"
            }
        }
        return (
            // TODO control iframe https://stackoverflow.com/a/28766463/10901608
            // make it responsive: https://www.w3schools.com/howto/howto_css_responsive_iframes.asp
            <div
                className={"responsive_iframe_container " + className}>
                <iframe title={media && media.title ? media.title : "embedded content"}
                        style={{border: "0"}}
                        className={"responsive_iframe"}
                        id={"media-iframe"}
                        ref={iframeRef}
                        src={embedSrc}
                        width={w} height={h}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; storage-access"
                        allowFullScreen

                />
                {/*<iframe title="deezer-widget" src="https://widget.deezer.com/widget/auto/track/70018662" width="100%"*/}
                {/*        height="300" frameBorder="0" allowTransparency="true"*/}
                {/*        allow="encrypted-media; clipboard-write"></iframe>*/}
                {/*https://youtube.com/clip/Ugkx7OuDobSALJ6StWnx_OxzvAbn-LN5Mnv3?si=1kkSKiN8maTk3Unv*/}
                {/*<iframe width="560" height="315"*/}
                {/*        src="https://www.youtube.com/embed/tmB1g_SCjfw?si=0_LjSjOQXtYsHeGS&amp;controls=0&amp;clip=Ugkx7OuDobSALJ6StWnx_OxzvAbn-LN5Mnv3&amp;clipt=EIu6rQkY9p6wCQ"*/}
                {/*        title="YouTube video player" frameBorder="0"*/}
                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                {/*        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
            </div>
        )
    }

}
